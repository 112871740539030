.trending {
  //   display: flex;
  //   height: 50rem;
  //   overflow-x: scroll;
  text-align: left;
  font-size: 2rem;
  color: #fff;
  padding-top: 2rem;
  overflow-x: scroll;
  &::-webkit-scrollbar {
    height: 4px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background: gray;
  }

  &_header {
    border-bottom: 1px solid rgba(128, 128, 128, 0.6);
    display: flex;
    justify-content: flex-start;
    padding: 0 2rem;
  }

  &_body {
    display: flex;
    width: auto;
  }

  &_table {
    border: none;
    padding: 2rem 0 0 2rem;
    margin-right: 4rem;
    min-width: 60%;
    margin-bottom: 2rem;

    th {
      // height: 2rem;
      padding-bottom: 2rem;
    }

    &_header {
      font-size: 2rem;
    }
  }

  &_index {
    font-size: 2rem;
  }

  &_collection {
    display: flex;
    align-items: center;

    > img {
      width: 8rem;
      height: 8rem;
      border-radius: 8px;
      object-fit: cover;
    }

    > div {
      margin-left: 2rem;

      > P {
        margin: 0;
      }

      > span {
        font-weight: 600;
        font-size: 1.8rem;
        margin: 0;
      }
    }
  }

  &_btn {
    background-color: transparent;
    border: 0;
    padding: 2rem 0;
    color: #fff;
    margin-right: 2rem;
    font-size: 2rem;
    cursor: pointer;

    &:is(:hover, :active, :focus-within) {
      border-bottom: 1px solid #db469f;
    }
  }

  &_active {
    border-bottom: 1px solid #db469f;
  }

  > span {
    font-size: 3rem;
    margin: 2rem 0 0 2rem;
  }

  &_nft {
    display: flex;
    flex-direction: column;
    width: 40rem;
    margin-right: 2rem;

    img {
      height: 30rem;
      object-fit: cover;
    }
    div {
      background-color: rgb(52, 49, 78);
      display: flex;
      flex-direction: column;
      padding: 1rem;

      span {
        margin: 0;
      }
    }

    &s {
      display: flex;
      overflow-x: scroll;
      padding: 3rem;
    }
  }
}

.collection {
  &s {
    padding: 2rem;
    display: flex;
    overflow-x: scroll;
    padding-bottom: 3rem;

    &::-webkit-scrollbar {
      height: 4px;
    }
    &::-webkit-scrollbar-track {
      background: transparent;
    }
    &::-webkit-scrollbar-thumb {
      background: gray;
    }
  }

  min-width: 35rem;
  height: 40rem;
  display: flex;
  flex-direction: column;
  margin-right: 2rem;

  &_title {
    color: #fff;
    text-align: left;
    font-size: 2rem;
    font-weight: 500;
    margin: 4rem 0 0 2rem;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &_details {
    padding: 0.5rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background-color: rgb(40, 37, 65);
    color: #fff;

    div {
      display: flex;
      justify-content: space-between;
      width: 100%;

      span {
        color: gray;

        p {
          color: #fff;
        }
      }
    }
  }
  &_name {
    margin: 0 0 1rem;
    font-size: 1.9rem;
    font-weight: 700;
  }
}

@media (max-width: 500px) {
  .trending {
    &_table {
      min-width: 48rem;
    }
  }
}
