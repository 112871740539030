.backdrop {
    // height: 65rem;
    // width: 170rem;
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    z-index: 100;
    background: rgba(0, 0, 0, 0.8);
    display: block;
    opacity: 0;
    transform: translateY(-200vh);
}

.open {
    opacity: 1 !important;
    transform: translateY(0) !important;
}