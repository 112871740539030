.logo {
  // width: 20%;
  position: relative;
  height: 100%;
  display: flex;
  min-width: 13rem;
  // align-items: center;

  //   a {
  //     display: flex;
  //     align-items: center;
  //     flex-grow: 2;
  //     width: 100%;
  //   }

  &__title {
    font-size: 4rem;
    align-self: center;
    color: white;
    position: absolute;
    left: 5rem;
  }

  &__icon {
    margin-bottom: 0.5rem;
    width: 6rem;
    height: 6rem;
  }
}

// @media (max-width: 440px){
//     .logo{
//         margin-right: 0;
//         flex-grow: 0;
//         justify-content: center;
//     }
// }
