.search {
  position: relative;
  // margin-left: 4rem;
  // margin-right: 2rem;
  display: flex;
  align-items: center;
  width: 90rem;
  margin-left: 10rem;
  position: relative;
  flex-grow: 1;

  &__field {
    outline: none;
    height: 4rem;
    width: 100%;
    border: 1px solid #7891d5;
    border-radius: 5rem;
    padding-left: 4rem;
    background-color: transparent;
    font-size: 2rem;
    color: white;
    word-spacing: 1px;
    letter-spacing: 2px;

    // &:hover,
    // &:focus,
    &:active,
    &:focus {
      // border: 1px solid #db469f;
      background-color: rgba(128, 128, 128, 0.555);
      color: white;
    }
  }

  &__btn {
    height: 4rem;
    background-color: #db469fbd;
    color: white;
    border-radius: 5rem;
    position: absolute;
    right: 0;
    border: 0;
    width: 12rem;
    font-size: 2.2rem;
    font-weight: 100;

    &:is(:hover, :active) {
      cursor: pointer;
    }
  }

  &__icon {
    height: 2rem;
    width: 2rem;
    fill: white;
    // color: aliceblue;
    position: absolute;
    left: 1rem;
    top: 0.7rem;
  }
}

@media (max-width: 1320px) {
  .search {
    margin-left: 5rem;
  }
}

@media (max-width: 1280px) {
  .search {
    margin-left: 4rem;
  }
}

@media (max-width: 1100px) {
  .search {
    margin-left: 3rem;
  }
}

@media (max-width: 1000px) {
  .search {
    margin-right: 3rem;
  }
}

@media (max-width: 950px) {
  .search {
    margin-right: 0;
    width: 40rem;
  }
}

@media (max-width: 900px) {
  .search {
    margin-right: 0;
    margin-left: 2rem;
    width: 40rem;
  }
}

@media (max-width: 800px) {
  .search {
    margin-right: 1rem;
  }
}

@media (max-width: 715px) {
  .search {
    width: 50%;
  }
}

@media (max-width: 600px) {
  .search {
    display: none;

    &__field {
      font-size: 1.4rem;
      padding-left: 2rem;
    }
  }
}
