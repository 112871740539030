.logout-btn {
    // height: 2.5rem;
    // width: 5rem;
    padding: .8rem;
    background-color: transparent;
    border: 2px solid #db469f;
    color: #db469f;
    cursor: pointer;
    font-weight: 700;
    font-size: 1rem;
    margin-left: 1.6rem;

    &:hover {
        background-color: #db469f;
        color: black;
    }
}
