.section-hero {
  padding: 7rem 10rem 8rem 0rem;
  margin: auto;
  // font-family: "Courier New", Courier, monospace;
  font-size: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: linear-gradient(
      rgba(16, 29, 44, 0.9),
      rgba(16, 29, 44, 0.9)
    ),
    url("../../assets/images/background-Image.jpg");
  background-size: cover;
  // background-size: auto;
  background-repeat: no-repeat;
  // object-fit: cover;
  background-position: top;

  position: relative;
  height: 80rem;
  color: #fff;
}

.row {
  display: flex;

  &__content {
    font-size: 8rem;
  }
}
.col-1-of-2 {
  // height: 40rem;
  max-width: 30rem;
  font-size: 7rem;
  // display: flex;
  // flex-direction: column;

  &:not(:last-child) {
    margin-right: 10rem;
  }
  // padding: 2rem;
  // width: 30%;
}

.hero {
  &__nft {
    &-image {
      height: 70%;
      width: 100%;

      img {
        width: 100%;
        height: 100%;
        border-radius: 10px 10px 0 0;
      }
    }

    &-container {
      display: flex;
      flex-direction: column;
      height: 100%;
      width: 100%;
    }

    &-details {
      display: flex;
      padding: 0.5rem 1rem;
      background-color: rgb(63, 62, 77);
      font-size: 6rem;

      & img {
        height: 4rem;
        width: 4rem;
        border-radius: 50%;
        margin-right: 1rem;
      }
    }
    &-description {
      display: flex;
      flex-direction: column;

      h3 {
        margin: 0;
      }

      span {
        font-size: 1.2rem;
      }
    }
  }
}

.btn__container {
  display: inline-block;
  margin-top: 8rem;
  background: linear-gradient(to right, #db469f, #7891d5, #db469f);
  background-position: 0% 50%;
  background-size: 150% 100%;
  justify-self: flex-end;
  border-radius: 1.5rem;
  &:hover {
    animation: gradient 1.5s forwards;
    // opacity: 0.6;
  }
}

.explore-cta {
  background-color: transparent;
  cursor: pointer;
  font-size: 1.2rem;
  width: 12rem;
  padding: 1rem;
  color: white;
  font-size: 1.2rem;
  margin-right: 0;
  text-align: center;
  display: flex;
  justify-content: center;
  text-decoration: none;
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
  font-weight: bold;
}

.authors {
  padding: 2rem;
}

.author {
  img {
    width: 5rem;
    height: 5rem;
    border-radius: 50%;
  }
}

@media (max-width: 900px) {
  .section-hero {
    padding-left: 10rem;
  }
  .col-1-of-2 {
    &:not(:last-child) {
      margin-right: 7rem;
    }
  }
}

@media (max-width: 700px) {
  .col-1-of-2 {
    &:not(:last-child) {
      margin-right: 0;
      display: none;
    }
    font-size: 10rem;
  }

  .section-hero {
    padding: 5rem 0 5rem;
  }
  .hero__nft-container {
    display: none;
  }
}
