.footer {
  background-color: black;
  width: 100%;
  color: white;
  // height: rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 6rem 2rem 2rem;

  &_socials {
    border-bottom: 1px solid #7891d5;
    width: 100%;
    padding-bottom: 2rem;

    a {
      margin-right: 2rem;

      img {
        width: 5rem;
        height: 5rem;
      }
    }
  }

  &_body {
    // display: flex;
    margin-top: 2rem;
    width: 100%;
    h2 {
      font-size: 2.4rem;
      letter-spacing: 0.2rem;
      margin: 0;
    }
    p {
      margin: 0;
      font-size: 2rem;
      letter-spacing: 0.2rem;
      opacity: 0.6;
    }
  }

  &_links {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 2rem 0;
    padding: 0 16rem 4rem;
    border-bottom: 1px solid gray;
  }

  &_link {
    font-size: 1.8rem;
    display: flex;
    flex-direction: column;
    padding: 2rem;

    > a {
      color: rgba(255, 255, 255, 0.7);
      text-decoration: none;
      font-size: 2rem;
      font-weight: 600;
      margin-top: 1rem;

      &:hover {
        color: gray;
      }
    }
  }

  &_copyright {
    color: #fff;
    font-size: 1.8rem;
    font-weight: 600;

    a {
      text-decoration: none;
      color: #fff;
      font-weight: inherit;
    }
  }

  &__container {
    // display: flex;
    // flex-direction: column;
    // justify-content: center;
    // align-items: stretch;
    padding: 1rem;
    display: grid;
    grid-auto-rows: auto;
    row-gap: 1rem;
    width: 80rem;
  }

  &__nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  &-nav__link {
    font-size: 2rem;
    text-decoration: none;
    color: white;

    &:not(:last-child) {
      margin-right: 5rem;
    }

    &:hover {
      color: gray;
    }
  }

  &__soc-cop {
    display: flex;
    // flex-direction: column;
  }

  &__socials {
    // height: 3rem;
    display: flex;
  }
}

.copyright {
  font-family: "Nunito", sans-serif;
  text-align: center;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.6rem;
  width: 100%;
}

.dev {
  text-decoration: none;
  color: white;
  font-size: 1.2rem;
}

.social__link {
  // height: 3rem;
  width: 3rem;
  text-decoration: none;
}

.social-img {
  width: 100%;
}

@media (max-width: 910px) {
  .footer {
    &_links {
      padding: 0 8rem 4rem;
    }
  }
}

@media (max-width: 800px) {
  .footer {
    &_links {
      padding: 0 2rem 4rem;
    }
  }
}

@media (max-width: 730px) {
  .footer {
    &_link {
      margin-bottom: 4rem;
    }
  }
}
