.nav-bar {
  display: flex;
  align-items: center;
  color: #7891d5;
  background-color: black;
  // font-size: 4rem;
  padding: 0 4rem 0 3rem;
  height: 100%;

  a {
    text-decoration: none;
  }
}

.nav-btn {
  text-decoration: none;
  list-style: none;
  color: #7891d5;
  width: auto;
  height: 100%;
  &:not(:last-child) {
    margin-right: 1.6rem;
  }
  &:hover {
    border-bottom: 3px solid #db469f;
    opacity: 0.6;
  }
}

.addr-container {
  font-size: 2rem;
  width: 10rem;
  overflow-x: hidden;
}

.header {
  // position: fixed;
  z-index: 100;
  height: 10rem;
  width: 100%;
  // padding: 0 1rem;
}

.btns-container {
  // margin-left: 16rem;
  // min-width: 20%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 24rem;
  // flex-grow: 1;
}

.mobile__connect {
  display: none;
}

.nav {
  display: flex;
  margin: 0;
  align-items: center;
  width: 30rem;
  padding: 0;
  margin-left: 5rem;
  justify-content: space-between;
  height: 100%;
}

@media (max-width: 1360px) {
  .nav {
    margin-left: 4rem;
  }
}

@media (max-width: 1250px) {
  .nav {
    margin-left: 4rem;
  }
  .btns-container {
    width: 25rem;
  }
}

@media (max-width: 1180px) {
  .nav {
    // width: 15rem;
    margin-left: 3rem;
    justify-content: space-between;
  }
}

@media (max-width: 1100px) {
  .btns-container {
    width: auto;
    margin-left: 3rem;
  }
}

@media (max-width: 1000px) {
  .btns-container {
    margin-left: 1.6rem;
  }
  .nav {
    width: 20rem;
    margin-left: 0;
  }
}

@media (max-width: 950px) {
  .nav {
    margin-left: 2rem;
    width: 16rem;
  }
}

@media (max-width: 800px) {
  .nav-bar {
    padding-right: 2rem;
  }
}

@media (max-width: 815px) {
  .nav-bar {
    padding-right: 2rem;
  }
}

@media (max-width: 815px) {
  .nav-bar {
    padding-right: 1rem;
  }
}

@media (max-width: 750px) {
  .nav {
    width: auto;
  }
}

@media (max-width: 700px) {
  .nav {
    display: none;
  }
}

@media (max-width: 600px) {
  // .logo{
  //     // align-self: center;
  //     // margin-right: 6rem;
  //     // width: 100%;
  //     flex-grow: 1;
  //     display: flex;
  //     justify-content: flex-end;
  //     margin-right: 0 !important;
  // }
  .nav-bar {
    justify-content: space-between;
    padding-right: 3rem;
  }
  .toggle-button {
    margin-left: 0.9rem;
  }
  .btns-container {
    flex-grow: 0;
    width: 50%;
  }
}

@media (max-width: 500px) {
  .logout-btn {
    margin-left: 1rem;
  }
}

@media (max-width: 363px) {
  .nav-bar {
    padding-left: 2rem;
  }
}
