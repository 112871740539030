.wallet__container {
  position: fixed;
  left: 50%;
  top: 50%;
  opacity: 1;
  transform: translateY(-1000px) translate(-50%, -50%);
  background-color: rgb(22 21 34);
  color: aliceblue;
  height: 70rem;
  width: 50rem;
  font-size: 8rem;
  z-index: 101;
  padding: 2rem;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: all 0.6s ease-in;
}

.wallets {
  display: flex;
  flex-direction: column;
  flex-grow: 1.5;
  margin-top: 12rem;
  align-items: center;
  // justify-content: center;
}

.wallet__btn {
  background-color: rgba(0, 0, 0, 0.2);
  height: 5rem;
  width: 90%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.8rem 3rem;
  font-size: 3.2rem;
  border: 0px solid rgba(9, 147, 236, 0.5);
  border-radius: 0.5rem;
  border-width: 1px;
  cursor: pointer;

  &:not(:last-child) {
    margin-bottom: 4rem;
  }

  &:hover {
    border: 1px solid #db469f;
  }
}

.select-wallet {
  span {
    font-size: 2.2rem;
    font-family: "Courier New", Courier, monospace;
    font-weight: 900;
  }
}

.wallet__text--container {
  text-align: center;
}

.wallet__name {
  width: auto;
  margin-right: 24%;
  font-weight: 400;
  font-size: 2rem;
  letter-spacing: 2px;
}

.wallet__img {
  height: 100%;
}

.metamask__login {
  display: flex;
}

.open-wallets {
  opacity: 1 !important;
  animation: wallet 1s forwards;
}

.close-wallets {
  width: 7rem;
  height: 7rem;
  padding: 2rem;
  text-align: center;
  vertical-align: middle;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(117, 110, 110, 0.308);
  // border: .5px solid black;
  // box-shadow: ;
  top: -7.5rem;
  position: absolute;
  right: 0;
  border-radius: 50%;
  cursor: pointer;

  &:hover {
    opacity: 0.5;
  }
}

@keyframes wallet {
  0% {
    transform: translateY(-1000px) translate(-50%, -50%);
  }
  80% {
    transform: translateY(100px) translate(-50%, -50%);
  }
  100% {
    transform: translateY(0) translate(-50%, -50%);
  }
}

@media (max-width: 1000px) {
  // .wallet__container {
  //   width: 45rem;
  //   height: 35rem;
  // }
}

@media (max-width: 530px) {
  .wallet__container {
    // width: 40rem;
    // height: 30rem;
  }
}

@media (max-width: 400px) {
  .wallets {
    margin-top: 10rem;
  }
  .wallet__container {
    height: 60rem;
    width: 45rem;
  }
  .wallet__btn {
    &:not(:last-child) {
      margin-bottom: 3rem;
    }
  }
  .close-wallets {
    width: 4rem;
    height: 4rem;
    font-size: 1rem;
    top: -5rem;
  }
}

@media (max-width: 310px) {
  .wallet__container {
    // width: 30rem;
    // height: 25rem;
  }
}
