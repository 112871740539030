.toggle-button {
  width: 2rem;
  background: transparent;
  border: none;
  cursor: pointer;
  vertical-align: middle;
  margin-left: 1rem;
  padding: 0;
  display: none;
  order: 1;
}
.toggle-button:focus {
  outline: none;
}
.toggle-button__bar {
  width: 100%;
  height: 0.2rem;
  background: #db469f;
  display: block;
  margin: 0.6rem 0;
}

@media (max-width: 1000px) {
  .toggle-button {
    display: block;
  }
}

@media (max-width: 800px) {
  .toggle-button {
    margin-left: 0.8rem;
  }
}
